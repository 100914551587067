import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {PMREMGenerator, UnsignedByteType, WebGLRenderer} from "three";

export function getCubeMapTexture ( renderer: WebGLRenderer, environment: { path: string } ) {
  const pmremGenerator = new PMREMGenerator( renderer );
  pmremGenerator.compileEquirectangularShader();
  const { path } = environment;

  // no envmap
  if ( ! path ) return Promise.resolve( { envMap: null } );

  return new Promise( ( resolve, reject ) => {

    new RGBELoader()
      .setDataType( UnsignedByteType )
      .load( path, ( texture ) => {

        const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
        pmremGenerator.dispose();

        resolve( { envMap } );

      }, undefined, reject );

  });

}
