// import {BufferGeometry, Mesh, MeshStandardMaterial, Vector3} from "three";
import {GLTF} from "three/examples/jsm/loaders/GLTFLoader";
// import {loadGLTF} from "../utils/gltf-loader";
import {BaseModel} from "./base.model";

export class StarModel extends BaseModel {
  model: GLTF | null;

  constructor() {
    super()
  }

  // create({ x, y, z }: Vector3): Mesh<BufferGeometry, MeshStandardMaterial> {
  //   let mesh = new Mesh<BufferGeometry, MeshStandardMaterial>()
  //   if (this.model) {
  //     this.model?.scene.traverse(node => {
  //       const obj = node as Mesh<BufferGeometry, MeshStandardMaterial>
  //       mesh = obj
  //     })
  //     mesh.position.set(x, y, z)
  //   }
  //   return mesh
  // }
  //
  // async loadModel() {
  //   this.model = await loadGLTF('star.gltf')
  // }
}
