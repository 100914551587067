import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

const loader = new GLTFLoader()

export async function loadGLTF(path: string) {
  try {
    const model = await loader.loadAsync(path)
    return model
  } catch (e) {
    console.log(e)
    return null
  }
}
