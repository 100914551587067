// create an AudioListener and add it to the camera
import {Camera,AudioListener, Audio, AudioLoader as ThreeAudioLoader } from "three";

export namespace AudioLoader {
  const listener = new AudioListener()
  let sound: Audio
  let audioLoader: ThreeAudioLoader

  export function init(camera: Camera) {
    camera.add( listener );
    sound = new Audio( listener );
    audioLoader = new ThreeAudioLoader()
  }

  export const loadAudio = async (path: string, volume = .5): Promise<Audio<GainNode>> => {
    return new Promise((resolve, reject) => {
      audioLoader.load(path, function (buffer) {
        sound.setBuffer(buffer);
        sound.setVolume(volume)
        resolve(sound)
      }, undefined, err => {
        reject(err)
      })
    })
  }
}
