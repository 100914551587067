import {Game} from './game'
import livesImgUrl from './assets/images/strike.svg'

export const GAME_OPTIONS = {
  colors: ['#7121DD', '#ff39ce'],
  // colors: ['#f16158'],
  coupons: [
    {id: '1', gameImgUrl: 'images/coupon-1.png'},
    {id: '2', gameImgUrl: 'images/coupon-2.png'},
    {id: '3', gameImgUrl: 'images/coupon-3.png'}
  ],
  livesCount: 3,
  maxScore: 20,
  gameSpeed: 500
}

console.log(window.location)

const query = new URLSearchParams(window.location.search)
const colors = query.get('colors')?.split(',') || []
if (colors.length) GAME_OPTIONS.colors = colors

function extractCouponsFromJSON(json: string | null) {
  try {
    return json ? JSON.parse(json) : []
  } catch (e) {
    return []
  }
}



const coupons = extractCouponsFromJSON(query.get('coupons'))
if (coupons.length) GAME_OPTIONS.coupons = coupons
// const livesCount = query.get('livesCount') || ''
// if (livesCount) GAME_OPTIONS.livesCount = livesCount
// const maxScore = query.get('maxScore') || ''
// if (maxScore) GAME_OPTIONS.maxScore = maxScore

// Set Lives UI
const livesDiv = document.querySelector('.lives')
for (let i = 0; i < GAME_OPTIONS.livesCount; i++) {
  const img = document.createElement('img')
  img.src = livesImgUrl
  img.classList.add('life')
  img.style.backgroundColor = GAME_OPTIONS.colors[0]
  livesDiv?.appendChild(img)
}

// Set Coupons UI
const couponsDiv = document.querySelector('.coupons') as HTMLDivElement
couponsDiv.style.borderColor = GAME_OPTIONS.colors[0]
// couponsDiv.style.backgroundColor = GAME_OPTIONS.colors[0]
for (const coupon of GAME_OPTIONS.coupons) {
  const couponDiv = document.createElement('div')
  couponDiv.classList.add('coupon')
  const img = document.createElement('img')
  img.src = coupon.gameImgUrl
  couponDiv.appendChild(img)
  couponDiv.setAttribute('data-coupon-id', coupon.id)
  couponDiv.style.borderColor = GAME_OPTIONS.colors[0]
  // couponsDiv?.appendChild(couponDiv)
}

const game = new Game('app', GAME_OPTIONS)

game.onScoreUpdate = (score) => {
  const gradientPercentage = score / GAME_OPTIONS.maxScore * 100
  couponsDiv.setAttribute('style', `background: linear-gradient(to right, ${GAME_OPTIONS.colors[0]} ${gradientPercentage}%, white ${gradientPercentage}% ${100 - score}%);`)
  couponsDiv.style.background = `linear-gradient(to right, purple 50%, white 50% 50%);`
}

game.onLivesUpdate = (lives) => {
  const div = livesDiv?.children[livesDiv?.children.length - 1]
  div?.remove()
}

game.onGameOver = (score: number) => {
  const couponId = getActiveCoupon(score)?.id
  emitEvent(GameEventType.GameOver, { couponId })
}

function getActiveCoupon(score: number) {
  const scorePerCoupon = GAME_OPTIONS.maxScore / GAME_OPTIONS.coupons.length
  const couponIndex = score / scorePerCoupon
  return couponIndex > 0 ? GAME_OPTIONS.coupons[Math.floor(couponIndex)] : null
}

// EVENTS
enum GameEventType {
  GameOver = 'GAME_OVER',
  GameLoaded = 'GAME_LOADED',
  GameWon = 'GAME_WON',
  GameLost = 'GAME_LOST',
}
interface GameEvent {
  type: GameEventType,
  payload: any
}

function emitEvent(type: GameEventType, payload?: any) {
  window.parent.postMessage({type, payload}, '*')
}

window.addEventListener('message', ({ data }: { data: GameEvent }) => {
  console.log(data)
})

// const emitters = {
//   gameOver: (couponId?: string) => emitEvent(GameEvent.GameWon, { couponId }),
//   gameLoaded: () => emitEvent(GameEvent.GameLoaded)
// }

// document.addEventListener('load', () => {
//   console.log(document.getElementById('app'))
//   const game = new Game('app')
// })
